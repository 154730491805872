@tailwind base;
@tailwind components;
@tailwind utilities;

/* .scrollbar::-webkit-scrollbar{
    width: 2px;
}

.scrollbar::-webkit-scrollbar-thumb{
   background-color: #7775;
    
}
.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #777;
     
 }

.scrollbar::-webkit-scrollbar-track {
    background-color: #5555;
     
}
.scrollbar::-webkit-scrollbar-track:hover {
    background-color: #555;
     
} */

.swiper-slide {
  background-color: white;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-wrapper {
  display: flex;
}
